<template>
    <div>
        <img src="../assets/qzn_logo.png" alt="" width="100%">
        <el-menu :default-openeds="['1']">
            <el-submenu index="1">
                <template slot="title"><i class="el-icon-notebook-1"></i>学生信息</template>
                
                <el-submenu index="1-1">
                    <template slot="title">成绩</template>
                    <el-menu-item index="1-1-1" @click.native="showVirtualData">模拟数据</el-menu-item>
                    <el-menu-item index="1-1-2" @click.native="showRealData">云计算三班成绩</el-menu-item>
                </el-submenu>
            </el-submenu>

            <!-- <el-submenu index="2">
                <template slot="title"><i class="el-icon-menu"></i>导航二</template>
                <el-menu-item-group>
                    <template slot="title">分组一</template>
                    <el-menu-item index="2-1">选项1</el-menu-item>
                    <el-menu-item index="2-2">选项2</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group title="分组2">
                    <el-menu-item index="2-3">选项3</el-menu-item>
                </el-menu-item-group>
                <el-submenu index="2-4">
                    <template slot="title">选项4</template>
                    <el-menu-item index="2-4-1">选项4-1</el-menu-item>
                </el-submenu>
            </el-submenu>

            <el-submenu index="3">
                <template slot="title"><i class="el-icon-setting"></i>导航三</template>
                <el-menu-item-group>
                    <template slot="title">分组一</template>
                    <el-menu-item index="3-1">选项1</el-menu-item>
                    <el-menu-item index="3-2">选项2</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group title="分组2">
                    <el-menu-item index="3-3">选项3</el-menu-item>
                </el-menu-item-group>
                <el-submenu index="3-4">
                    <template slot="title">选项4</template>
                    <el-menu-item index="3-4-1">选项4-1</el-menu-item>
                </el-submenu>
            </el-submenu> -->
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'ManageAside',
    methods:{
        showVirtualData(){
            this.$router.push({
                name:'virtualData'
            });
        },
        showRealData(){
            this.$router.push({
                name:'RealData'
            });
        }
    }
};
</script>
