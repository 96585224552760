import VueRouter from 'vue-router';
import axios from 'axios';


import loginView from '../pages/LoginView'
import ManageView from '../components/ManageView'
import VirtualData from '../pages/VirtualData'
import RealData from '../pages/RealData'
import { userAPI } from '@/api';


const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/loginView',
        },
        {
            meta: {
                title: '登录界面'
            },
            path: '/loginView',
            component: loginView
        },
        {
            path: '/ManageView',
            meta: {
                isAuth: true,
                title: '管理界面'
            },
            component: ManageView,
            beforeEnter(to, from, next) {
                auth(to, from, next);
            },
            children: [
                {
                    name: 'virtualData',
                    path: 'virtualData',
                    meta: {
                        isAuth: true,
                        title: '管理界面'
                    },
                    beforeEnter(to, from, next) {
                        auth(to, from, next);
                    },
                    component: VirtualData
                },
                {
                    name: 'RealData',
                    path: 'RealData',
                    meta: {
                        isAuth: true,
                        title: '管理界面'
                    },
                    beforeEnter(to, from, next) {
                        auth(to, from, next);
                    },
                    component: RealData
                },
            ]
        }
    ]
});
function auth(to, from, next) {
    if (to.meta.isAuth) {
        axios({
            url: userAPI + '/isAuth',
            headers: {
                'Authorization': sessionStorage.getItem('token')
            },
        }).then((res) => {
            if (res.data === '验证成功') {
                next()
            } else {
                router.replace('/loginView')
            }
        })
    }
}
router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = '登录界面'
    }
})
export default router;
