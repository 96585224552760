<template>
    <div>
        <router-view></router-view>

        <el-dialog title="修改密码" :visible.sync="isModifyUser" width="30%" center>
            密码：<el-input placeholder="请输入要修改的密码" v-model="password" show-password></el-input>
            重复密码：<el-input placeholder="请重复输入修改的密码" v-model="repassword" show-password></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isModifyUser = false">取 消</el-button>
                <el-button type="primary" @click="ModifyUser">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { userAPI } from '@/api';
import { mapState } from 'vuex';

export default {
    name: 'ManageMain',
    data() {
        return {
            password: '',
            repassword: '',
            isModifyUser: false,
        }
    },
    computed: {
        ...mapState('loginInfo', ['username'])
    },
    methods: {
        ModifyUser() {
            if (this.password.length === 0 || this.repassword.length === 0) {
                this.$message({
                    message: '密码不能为空哦',
                    type: 'warning'
                });
            } else {
                if (this.password === this.repassword) {
                    axios({
                        url: userAPI + '/modifyUser',
                        method: 'POST',
                        headers: {
                            'Authorization': sessionStorage.getItem('token')
                        },
                        data: {
                            user: this.username,
                            password: this.password
                        }
                    }).then(() => {
                        this.password = '';
                        this.repassword = '';
                        this.$message({
                            message: '恭喜你，修改成功',
                            type: 'success'
                        });
                        this.$router.replace('/loginView');
                    }).catch((err) => {
                        this.$message({
                            message: err.response.data,
                            type: 'warning'
                        });
                    })
                    this.isModifyUser = false
                } else {
                    this.$message({
                        message: '两次密码不一致哦',
                        type: 'warning'
                    });
                }
            }

        }
    },
    mounted() {
        this.$bus.$on('ModifyUser', (value) => {
            this.isModifyUser = value
        })
    }
};
</script>