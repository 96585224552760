import Vuex from 'vuex'
import Vue from 'vue'

import loginOptions from './login'
import manageOptions from './manage'

Vue.use(Vuex)

export default new Vuex.Store ({
    modules:{
        loginInfo:loginOptions,
        manageInfo:manageOptions
    },
})

