<template>
    <div>
        <el-table :data="tableData" height="600px" border style="width: 100%">
            <el-table-column prop="stu_id" label="学号" width="140">
            </el-table-column>
            <el-table-column prop="stu_name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="stu_class" label="班级">
            </el-table-column>
            <el-table-column prop="chinese" label="语文">
            </el-table-column>
            <el-table-column prop="mathematics" label="数学">
            </el-table-column>
            <el-table-column prop="english" label="英语">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { studentAPI } from '@/api';
import axios from 'axios';
export default {
    name: 'VirtualData',

    data() {
        return {
            tableData: [],
        }
    },
    mounted() {
        axios({
            url: studentAPI+'/list',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
            },
            methods: "GET",
        }).then((
            err => {
                this.$message({
                    message: err.response.data,
                    type: 'warning'
                });
            },
            res => {
                this.tableData = res.data.data
            }
        ))
    },
};
</script>