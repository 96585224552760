<template>
    <div>
        <span>当前用户:{{ username }}</span>
        <el-dropdown trigger="click">
            <i class="el-icon-user-solid" style="font-size: 25px;"></i>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="modifyUser">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="loginOut">登出</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'ManageHeader',
    data() {
        return {
            
        }
    },
    methods: {
        loginOut() {
            this.$notify({
                title: `用户：${this.username}`,
                message: '登出成功'
            });
            sessionStorage.clear();
            this.$router.replace('/loginView');
        },
        modifyUser() {
            this.$bus.$emit("ModifyUser",true)
        }
    },
    computed: {
        ...mapState('loginInfo', ['username'])
    },
};
</script>

<style scoped>
span {
    user-select: none;
    font-weight: bold;
    font-size: 20px;
    margin-right: 15px;
}
</style>