const loginOptions = {
    namespaced: true,
    actions: {

    },
    mutations: {
        SETUSERNAME(state, value) {
            sessionStorage.setItem('username', value);
            state.username = value;
        }
    },
    state: {
        username: sessionStorage.getItem('username') || '',
    },
    getters: {
        
    }
}
export default loginOptions