import Vue from 'vue'
import VueRouter from 'vue-router'
//引入组件
import {
  Notification, Container,
  Header, Aside,
  Main, Footer,
  Menu, Submenu,
  MenuItemGroup, MenuItem,
  Dropdown, DropdownMenu,
  DropdownItem, Table,
  TableColumn,Dialog,
  Button,Input,
  Message,Loading 
} from 'element-ui';

import App from './App.vue'
import router from './router'
import store from './store/index'

Vue.config.productionTip = false

//注册组件
Vue.component(Notification.name, Notification)
Vue.component(Container.name, Container)
Vue.component(Header.name, Header)
Vue.component(Aside.name, Aside)
Vue.component(Main.name, Main)
Vue.component(Footer.name, Footer)
Vue.component(Menu.name, Menu)
Vue.component(Submenu.name, Submenu)
Vue.component(MenuItemGroup.name, MenuItemGroup)
Vue.component(MenuItem.name, MenuItem)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)
Vue.component(Dialog.name, Dialog)
Vue.component(Button.name, Button)
Vue.component(Input.name, Input)

Vue.use(VueRouter);
Vue.use(Loading)

//全局注册通知
Vue.prototype.$notify = Notification;
//全局注册消息提示
Vue.prototype.$message = Message


new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
