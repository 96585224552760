const manageOptions = {
    namespaced: true,
    actions: {

    },
    mutations: {

    },
    state: {

    },
    getters: {

    }
}
export default manageOptions;