<template>
    <div>
        <el-container style="height: 100%; border: 1px solid #eee">
            <el-aside width="200px" style="background-color: rgb(238, 241, 246);height: 100%;">
                <ManageAside></ManageAside>
            </el-aside>
            <el-container>
                <el-header style="text-align: right; font-size: 12px">
                    <ManageHeader></ManageHeader>
                </el-header>
                <el-main>
                    <ManageMain></ManageMain>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import ManageHeader from './ManageHeader.vue';
import ManageMain from './ManageMain.vue';
import ManageAside from './ManageAside.vue';
export default {
    name: 'ManageView',
    components: { ManageHeader, ManageMain, ManageAside }
};
</script>

<style>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
}

.el-aside {
    color: #333;
}
</style>