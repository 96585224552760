<template>
    <div id="container">
        <div class="box">
            <div class="transtion-box">
                <div class="login-box">
                    <h1>欢迎回来</h1>
                    <section>
                        <label for="loginUser">用户</label>
                        <input type="text" id="loginUser" v-model="loginUser.user" />
                    </section>
                    <section>
                        <label for="loginPassword">密码</label>
                        <input type="password" id="loginPassword" v-model="loginUser.password" />
                        <span>忘记密码?</span>
                    </section>
                    <button type="button" @click="login">登录</button>
                </div>

                <div class="reg-box" style="display: none">
                    <h1>立即注册</h1>
                    <section>
                        <label for="registerUser">用户名</label>
                        <input type="text" id="registerUser" v-model="registerUser.user" />
                    </section>
                    <section>
                        <label for="registerPassword">密码</label>
                        <input type="password" id="registerPassword" v-model="registerUser.password" />
                    </section>
                    <section>
                        <label for="registerRePassword">重复密码</label>
                        <input type="password" id="registerRePassword" v-model="registerUser.repassword" />
                    </section>
                    <button type="button" @click="register">注册</button>
                </div>
            </div>

            <div class="transferToReg">
                <h1 class="title">还未注册？</h1>
                <span class="subTitle">立即注册，发现大量机会！</span>
                <button type="button" id="transfetBtn">注册</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'LoginView',
    data() {
        return {
            loginUser: {
                user: "",
                password: "",
            },
            registerUser: {
                user: "",
                password: "",
                repassword: "",
            }
        }
    },
    methods: {
        login() {
            axios({
                url: "http://120.77.60.198:3000/login",
                method: "POST",
                data: {
                    user: this.loginUser.user,
                    password: this.loginUser.password,
                }
            }).then((res) => {
                if (this.loginUser.user.length !== 0 && this.loginUser.password.length !== 0) {
                    this.$notify({
                        title: `用户：${this.loginUser.user}`,
                        message: '登录成功',
                    });
                    sessionStorage.setItem("token", res.data.token);
                    this.$store.commit('loginInfo/SETUSERNAME', this.loginUser.user)
                    this.$router.replace('/ManageView');
                }else{
                    this.$message({
                    message: '输入的账号或密码为空',
                    type: 'warning'
                });
                }
            }).catch((err) => {
                this.$message({
                    message: err.response.data,
                    type: 'warning'
                });
            })
        }, register() {
            // 弱校验：/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/   6~10位的数字+字母；字母+特殊字符，特殊字符+数字
            if (this.registerUser.user.match("[A-Za-z0-9_\-\u4e00-\u9fa5]+") &&
                //强校验：数字，字母，特殊字符，缺一不可
                (this.registerUser.password.match("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{6,12}$") ||
                    this.registerUser.password.match("^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$"))) {

                if (this.registerUser.password === this.registerUser.repassword) {
                    axios({
                        url: "http://120.77.60.198:3000/register",
                        method: "POST",
                        data: {
                            user: this.registerUser.user,
                            password: this.registerUser.password,
                        }
                    }).then(() => {
                        this.$notify({
                            title: `用户${this.registerUser.user}`,
                            message: '注册成功，请登录',
                        });
                    }).catch((err) => {
                        this.$message({
                            message: err.response.data,
                            type: 'warning'
                        });
                    })
                } else {
                    this.$message({
                        message: '两次密码不相同哦',
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: '输入的账号或密码不合法哦，请重新输入',
                    type: 'warning'
                });
            }
        },
    },
    mounted() {
        let transfer = document.getElementById('transfetBtn');
        transfer.addEventListener('click', function () {
            let login = document.querySelector('.login-box');
            let reg = document.querySelector('.reg-box');
            let total = document.querySelector('.transtion-box');
            let target = document.querySelector('.transferToReg');
            let title = document.querySelector('.title');
            let subTitle = document.querySelector('.subTitle');
            transfer.innerText === '注册'
                ? (() => {
                    target.style.left = 0;
                    total.style.left = 260 + 'px';
                    transfer.innerText = '登录';
                    title.innerText = '已有帐号？';
                    subTitle.innerText = '有帐号就登录吧，好久不见了！';
                    setTimeout(() => {
                        login.style.display = 'none';
                        reg.style.display = 'flex';
                    }, 300);
                })()
                : (() => {
                    target.style.left = 640 + 'px';
                    total.style.left = 0;
                    transfer.innerText = '注册';
                    title.innerText = '还未注册？';
                    subTitle.innerText = '立即注册，发现大量机会！';
                    setTimeout(() => {
                        login.style.display = 'flex';
                        reg.style.display = 'none';
                    }, 300);
                })();
        });
    },
};
</script>

<style>
#container {
    width: 100%;
    height: 100vh;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
}

#container .box {
    width: 900px;
    height: 550px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

#container .box .transtion-box {
    position: absolute;
    left: 0;
    transition: 0.5s all ease-in-out;
}

#container .box .transtion-box .login-box,
.reg-box {
    width: 640px;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 50px 30px;
}

#container .box .transtion-box h1 {
    margin-bottom: 35px;
}

#container .box .transtion-box section {
    display: flex;
    flex-flow: inherit;
    align-items: inherit;
    width: 100%;
    margin-bottom: 30px;
}

#container .box .transtion-box section label {
    font-size: 14px;
    color: #909399;
    text-transform: uppercase;
    margin-bottom: 8px;
}

#container .box .transtion-box section input {
    width: 50%;
    outline: 0;
    border: none;
    font-size: 18px;
    color: tomato;
    text-align: center;
    padding: 4px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

#container .box .transtion-box section span {
    color: rgb(80, 80, 77);
    font-size: 15px;
    cursor: pointer;
    margin-top: 18px;
}

#container .box .transtion-box button {
    width: 50%;
    padding: 6px 0;
    text-align: center;
    border: 3px solid #d4af7a;
    border-radius: 20px;
    background: #d4af7a;
    color: #fff;
    font-size: 17px;
    letter-spacing: 6px;
    text-indent: 6px;
    margin-bottom: 15px;
    cursor: pointer;
}

#container .box .transtion-box .other {
    border: 3px solid #d3dae9;
    background: #fff;
    color: rgb(124, 145, 184);
    font-weight: 600;
}

#container .box .transferToReg {
    width: 260px;
    height: 100%;
    background: linear-gradient(to bottom right,
            #0e92eb 0%,
            #5f90ec 50%,
            #b08fe5 100%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    left: 640px;
    top: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 50px 0;
    color: white;
    transition: all 1s ease-in-out;
}

#container .box .transferToReg .title {
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}

#container .box .transferToReg button {
    margin-top: 260px;
    width: 50%;
    padding: 8px 0;
    border-radius: 14px;
    letter-spacing: 10px;
    text-indent: 10px;
    font-size: 18px;
    color: #fff;
    border: 3px solid #fff;
    background: transparent;
    font-weight: 700;
    cursor: pointer;
}

#container .box .transferToReg button:hover {
    border: 3px solid #206dfc;
}
</style>