<template>
    <div>
        <el-table :data="RealData" height="600px" border style="width: 100%" v-loading="loading">
            <el-table-column type="index" width="50" label="序号">
            </el-table-column>
            <el-table-column prop="id" label="学号" width="140">
            </el-table-column>
            <el-table-column prop="姓名" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="平均分" label="平均分" sortable>
            </el-table-column>
            <el-table-column prop="平均学分绩点" label="平均绩点" sortable>
            </el-table-column>
            <el-table-column prop="总分" label="总成绩" sortable>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row.id)" type="text" size="small">查看</el-button>
                    <el-button type="text" size="small" disabled>编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="详细信息" :visible.sync="dialogTableVisible" width="40%">
            <el-table :data="subject" border height="400">
                <el-table-column label="学科">
                    <template slot-scope="scope">
                        {{ subject[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column label="成绩">
                    <template slot-scope="scope">
                        {{ RealData[student_index][subject[scope.$index]] }}
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { studentAPI } from '@/api';
import axios from 'axios';

export default {
    name: 'RealData',

    data() {
        return {
            RealData: [],
            loading: true,
            dialogTableVisible: false,
            subject: [],
            student_index: 0
        };
    },
    methods: {
        handleClick(id) {
            const index = this.RealData.findIndex((item) => {
                return item.id === id
            })
            this.student_index = index;
            this.dialogTableVisible = true;
        }
    },
    mounted() {
        axios({
            url: studentAPI + '/realStudents',
            methods: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('token')
            },
        }).then((
            err => {
                this.$message({
                    message: err.response.data,
                    type: 'warning'
                });
            },
            res => {
                this.RealData = res.data.data;
                this.subject = Object.keys(res.data.data[0]).splice(3, 33);
                this.loading = false
            })
        )
    }
};
</script>